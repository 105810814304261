export default {
  siteTitle: 'Get bonus',
  blogerName: 'ARLAN',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@criasdenegocios'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/arlan.cria/'
    },
    {
      name: 'telegram',
      url: 'https://t.me/grupocriasdenegocios'
    }
  ],
  projects: [
    {
      name: 'drip',
      url: 'https://dripcasino.life/ca2796a84',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c4d797d34',
      gameTitle: 'Izzi Art (BGaming)', // имя игры изменено. копировать внимательно!
    },
    {
      name: 'fresh',
      url: 'https://fresh-azhqmrpiks.com/c4a8001a7',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c711a396f',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/ceb32698d',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c7bddc504',
      gameTitle: 'Starda Queen (BGaming)', // имя игры изменено. копировать внимательно!
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/cb26a5bbc',
      gameTitle: 'Legzo Punk (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>ARLAN</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'ARLAN',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
